<template>
  <v-container fluid>
    <CRUD
      ref="crud"
      id-method="params"
      :fields="fields"
      :table="table"
      :api="api"
      @changed:mode="resetForm"
      @form:input="onFormInput"
    >
      <template v-slot:[`form.input.IONo`]="{ item }">
        <v-text-field
          hide-details="auto"
          dense
          :error-messages="ioNoErrMsg"
          :label="item.label"
          :value="item.value"
          @input="validateIO($event)"
          @change="onIOChange($event, item)"
        ></v-text-field>
      </template>
    </CRUD>
  </v-container>
</template>

<script>
import axios from "axios";
import CRUD from "@/components/util/CRUD/CRUD";

export default {
  components: {
    CRUD
  },
  data() {
    return {
      source: undefined,
      ioNoErrMsg: "",
      api: {
        url: "/ccms/table/",
        params: {
          tableName: "cnetd.io"
        }
      },
      table: {
        text: "IO",
        key: "ioId",
        default: {
          itemsPerPage: 20,
          sortBy: ["UpdateDate"],
          sortDesc: [true]
        },
        components: {
          filter: true,
          quickFilter: [
            {
              name: "AreaID",
              width: "400px",
              value: this.$store.state.auth.user.areaid
            }
          ]
        }
      },
      fields: [
        {
          text: "IO No",
          name: "IONo",
          searchable: true,
          input: {
            validation: ["mandatory"]
          }
        },
        {
          text: "IO Amount",
          name: "LocalAmt",
          searchable: true,
          input: {
            type: "N"
          }
        },
        {
          text: "Campaign Name",
          name: "CampaignNM",
          searchable: true,
          input: {
            validation: ["mandatory"]
          }
        },
        {
          text: "Campaign Budget",
          name: "CampaignBudget",
          searchable: true,
          input: {
            validation: ["mandatory"],
            type: "N"
          }
        },
        {
          text: "Country Office",
          name: "AreaID",
          listable: false,
          editable: false,
          searchable: true,
          input: {},
          foreign: {
            table: "cnetd.areamst",
            key: "AreaID",
            display: "AreaNM"
          }
        },
        {
          text: "Revenue Month",
          name: "RevMth",
          searchable: true,
          input: {
            type: "D"
          }
        },
        {
          text: "Order Date",
          name: "OrderDate",
          searchable: true,
          input: {
            type: "D"
          }
        },
        {
          text: "Start Date",
          name: "StartDate",
          searchable: true,
          input: {
            type: "D"
          }
        },
        {
          text: "End Date",
          name: "EndDate",
          searchable: true,
          input: {
            type: "D"
          }
        },
        {
          text: "Sales Person",
          name: "StaffID",
          searchable: true,
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.staffmst",
            key: "StaffID",
            display: "StaffNM",
            where: `StaffTypeID = 1 AND AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "Client",
          name: "CtCustID",
          searchable: true,
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.clientmst",
            key: "ClientID",
            display: "ClientNM",
            where: `AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "GST",
          name: "GST",
          searchable: true,
          input: {
            type: "N"
          }
        },
        {
          text: "Currency",
          name: "Currency",
          searchable: true,
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.currencymst",
            key: "CurrencyID",
            display: "CurrencyNM"
          }
        },
        {
          text: "Internal Cost",
          name: "InternalCost",
          listable: false,
          input: {
            control: "checkbox"
          }
        },
        {
          text: "Create By",
          name: "CreateBy",
          listable: false,
          editable: false
        },
        {
          text: "Created Date",
          name: "CreateDate",
          listable: false,
          editable: false
        },
        {
          text: "Updated By",
          name: "UpdateBy",
          listable: false,
          editable: false
        },
        {
          text: "Updated Date",
          name: "UpdateDate",
          listable: false,
          editable: false
        }
      ]
    };
  },
  methods: {
    onFormInput(e) {
      if (e.item.field == "LocalAmt") {
        this.$refs.crud.updateValue("CampaignBudget", autoFillBudgetCampaign(e.item.value));
      }
    },
    validateIO(ioNo) {
      this.source && this.source.cancel("Operation canceled due to new request.");
      this.source = axios.CancelToken.source();
      if (ioNo) {
        this.$axios
          .get("/ccms/checkIO", {
            params: {
              ioNo: ioNo,
              ioId: this.$route.params.id
            },
            cancelToken: this.source.token
          })
          .then((res) => {
            if (res.data) {
              this.ioNoErrMsg = "Duplicate IO No is not accepted";
            } else {
              this.ioNoErrMsg = "";
            }
          });
      }
    },
    onIOChange(value, item) {
      this.$refs.crud.updateValue(item.field, value);
    },
    resetForm(e) {
      if (e != "list") {
        this.ioNoErrMsg = "";
      }
    }
  }
};

function roundit(Num, Places) {
  if (Places > 0) {
    if (Num.toString().length - Num.toString().lastIndexOf(".") > Places + 1) {
      var Rounder = Math.pow(10, Places);
      return Math.round(Num * Rounder) / Rounder;
    } else return Num;
  } else return Math.round(Num);
}

function autoFillBudgetCampaign(val) {
  return roundit(val * 0.15, 2);
}
</script>
